<template>
  <v-app style="z-index: 1">
    <app-bar/>
    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
    <app-footer/>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'App',

  components: { AppFooter, AppBar },

  data: () => ({}),

  created() {
  }
};
</script>

<style>
html {
  overflow-y: auto !important;
}

@media (min-width: 1904px) {
  .col-xl-fifth {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.v-input {
  color: #febd3b !important;
  caret-color: #febd3b !important;
}

.v-input fieldset {
  border-color: #febd3b !important;
  border-radius: 0 !important;
}

.v-text-field__details, .v-text-field__details .v-messages {
  min-height: 0 !important;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(254, 189, 59, 0.12) !important;
}

.v-application {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.text-primary {
  color: #febd3b;
}
</style>